import CloseIcon from "@mui/icons-material/Close";
import { Box, Snackbar, useTheme } from "@mui/material";
import { useEffect, useRef, useReducer } from "react";
import * as React from "react";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";

import { AlertDialog, RobotoIconButton } from "@/components";
import { uploadHandler } from "@/components/DragAndDropFiles/uploadHandler";
import { useUploadState } from "@/components/DragAndDropFiles/useUploadState";
import { useAnnotationData } from "@/domain/files/hooks/useAnnotationData";
import { useAnnotationFiles } from "@/domain/files/hooks/useAnnotationFiles";
import { useDeleteDirectoryMutation } from "@/domain/files/hooks/useDeleteDirectoryMutation";
import { useDeleteFileMutation } from "@/domain/files/hooks/useDeleteFileMutation";
import { useDirectoryContents } from "@/domain/files/hooks/useDirectoryContents";
import { useDirectoryExtensions } from "@/domain/files/hooks/useDirectoryExtensions";
import { useAuth } from "@/providers";
import { ErrorMonitoringService } from "@/service";
import { Dataset, FileSystemNode, isDirectory, isFile } from "@/types";

import { DatasetOperationBar } from "../DatasetOperationBar";

import { FileBrowserActionTypes, FileBrowserAction } from "./actions";
import { DatasetBreadcrumbs } from "./DatasetBreadcrumbs";
import { DragAndDropDisplay } from "./DragAndDropDisplay";
import { computeExtensionsArray } from "./helper";
import { fileBrowserReducer } from "./reducer";
import { FileBrowserState } from "./state";

const sliderValueFormat = (value: number) => {
  return `Zoom: ${value}`;
};

interface DatasetFileBrowserProps {
  dataset: Dataset | undefined;
  datasetId: string;
  datasetPath: string[];
}

export const DatasetFileBrowser: React.FC<DatasetFileBrowserProps> = ({
  dataset,
  datasetId,
  datasetPath,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { currentOrganization } = useAuth();
  const [searchParams, setSearchParams] = useSearchParams();
  const [uploadState, uploadActions] = useUploadState();
  const fileInputRef = useRef<HTMLInputElement>(null);

  const [fileBrowserState, dispatch] = useReducer<
    React.Reducer<FileBrowserState, FileBrowserAction>
  >(fileBrowserReducer, {
    alert: {
      isOpen: false,
      title: "",
      text: "",
      action: async () => {},
    },
    annotationFile: undefined,
    annotationViewFilters: {
      confidenceThreshold: { percent: 0, show: false },
      labels: { show: true },
      boundingBox: { show: true },
      segmentation: { show: false },
    },
    activeToggle: "all",
    displayMode: (searchParams.get("display") as string) ?? "table",
    error: null,
    fileTypes: [],
    gridItems: 3,
    searchTerm: "",
    selectedItems: [],
    showHiddenFiles: false,
    snackbarState: {
      isOpen: false,
      message: "",
    },
    tableConfig: {
      page: 0,
      rowsPerPage: 25,
      pageTokens: { 0: undefined },
    },
  });

  const pathString = datasetPath.slice(1).join("/");
  const annotationFilesQuery = useAnnotationFiles(datasetId, pathString);

  useEffect(() => {
    if (annotationFilesQuery.isSuccess) {
      dispatch({
        type: FileBrowserActionTypes.SetAnnotationFile,
        payload: annotationFilesQuery.data[0],
      });
    }
  }, [annotationFilesQuery.data, annotationFilesQuery.isSuccess]);

  const annotationDataQuery = useAnnotationData(
    fileBrowserState.annotationFile?.file,
  );

  const currentToken =
    fileBrowserState.tableConfig?.pageTokens[fileBrowserState.tableConfig.page];

  const deleteFileMutation = useDeleteFileMutation();
  const deleteDirectoryMutation = useDeleteDirectoryMutation();

  const directoryExtensionsQuery = useDirectoryExtensions(
    pathString,
    datasetId,
  );

  const directoryContentsQuery = useDirectoryContents(
    pathString,
    datasetId,
    dataset?.org_id,
    fileBrowserState.tableConfig.rowsPerPage,
    currentToken,
    fileBrowserState.searchTerm,
    fileBrowserState.showHiddenFiles,
    fileBrowserState.fileTypes,
  );
  const currentPage = directoryContentsQuery.data?.items;
  const nextToken = directoryContentsQuery.data?.nextToken;
  React.useEffect(() => {
    if (directoryContentsQuery.error) {
      dispatch({
        type: FileBrowserActionTypes.SetError,
        payload: directoryContentsQuery.error,
      });
    }
  }, [directoryContentsQuery.error]);

  const extensions = computeExtensionsArray(directoryExtensionsQuery.data);

  const handleSnackbarClose = (
    _event: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === "clickaway") {
      return;
    }

    dispatch({
      type: FileBrowserActionTypes.SetSnackbarState,
      payload: {
        isOpen: false,
        message: "",
      },
    });
  };

  const handleRowDoubleClick = (row: FileSystemNode) => {
    if (row.type === "directory") {
      let newPath = [...datasetPath, row.name].join("/");
      newPath =
        "/datasets/" + newPath + `?display=${fileBrowserState.displayMode}`;

      navigate(newPath);
    }
  };

  useEffect(() => {
    dispatch({
      type: FileBrowserActionTypes.SetTablePageToZero,
      payload: null,
    });
  }, [pathname]);

  const filePrefix =
    datasetPath.length > 1 ? datasetPath.slice(1).join("/") : undefined;

  const handleGridSliderChange = (
    _event: Event,
    newValue: number | number[],
  ) => {
    if (typeof newValue === "number") {
      dispatch({
        type: FileBrowserActionTypes.SetGridItems,
        payload: newValue,
      });
    }
  };

  function handleActionConfirmation(
    title: string,
    text: string,
    action: () => Promise<void>,
  ) {
    dispatch({
      type: FileBrowserActionTypes.SetAlertState,
      payload: {
        isOpen: true,
        title: title,
        text: text,
        action,
      },
    });
  }

  const handleFileUpload = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (!currentOrganization) {
      return;
    }
    try {
      await uploadHandler(
        event,
        currentOrganization.org_id,
        datasetId,
        uploadActions,
        filePrefix,
      );
      dispatch({
        type: FileBrowserActionTypes.SetSnackbarState,
        payload: {
          isOpen: true,
          message: "Files uploaded successfully",
        },
      });
    } catch (e) {
      const error = e instanceof Error ? e : new Error(String(e));
      uploadActions.fail(error);
    } finally {
      await directoryContentsQuery.refetch();
    }
  };

  function summarizeItems(items: FileSystemNode[]): string {
    const hasFiles = items.some(isFile);
    const hasDirectories = items.some(isDirectory);

    if (items.length > 1) {
      if (hasFiles && hasDirectories) {
        // Mixed case, using a general term
        return "Items";
      }
      return hasFiles ? "Files" : "Directories";
    }

    return isFile(items[0]) ? "File" : "Directory";
  }

  return (
    <>
      <Box
        sx={{
          display: "flex",
          mt: theme.spacing(0),
          mb: theme.spacing(1.5),
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <DatasetOperationBar
          datasetId={datasetId}
          extensions={extensions}
          fileTypes={fileBrowserState.fileTypes}
          display={fileBrowserState.displayMode}
          activeToggle={fileBrowserState.activeToggle}
          handleViewToggle={(display, fileTypes, activeToggle) => {
            dispatch({
              type: FileBrowserActionTypes.HandleViewToggle,
              payload: { display, fileTypes, activeToggle },
            });

            setSearchParams({
              display: display,
            });
          }}
          handleDeleteItems={(items) => {
            if (items.length === 0) {
              return;
            }

            const deleteTerm = summarizeItems(items);
            const deleteTitle = `Delete ${deleteTerm}`;
            handleActionConfirmation(
              `${deleteTitle}`,
              `Are you sure you want to delete ${items.length} ${deleteTerm.toLowerCase()}?`,
              async function () {
                try {
                  const deletePromises = [];

                  const directoryPaths = items
                    .filter(isDirectory)
                    .map((item) => item.directory.relative_path);

                  if (directoryPaths.length > 0) {
                    deletePromises.push(
                      deleteDirectoryMutation.mutateAsync({
                        datasetId: datasetId,
                        directoryPaths,
                      }),
                    );
                  }

                  const fileDeletePromises = items
                    .filter(isFile)
                    .map((item) => {
                      return deleteFileMutation.mutateAsync({
                        file: item.file,
                      });
                    });

                  deletePromises.push(...fileDeletePromises);

                  await Promise.all(deletePromises);

                  await directoryContentsQuery.refetch();

                  dispatch({
                    type: FileBrowserActionTypes.DeleteFiles,
                    payload: items,
                  });
                } catch (e) {
                  ErrorMonitoringService.captureError(e);
                }
              },
            );
          }}
          handleDeselectAll={() => {
            dispatch({
              type: FileBrowserActionTypes.SetSelectedItems,
              payload: [],
            });
          }}
          handleFileTypeSelection={(fileTypes) => {
            dispatch({
              type: FileBrowserActionTypes.SetFileTypes,
              payload: fileTypes,
            });
          }}
          handleFileSearch={(searchTerm) => {
            dispatch({
              type: FileBrowserActionTypes.SetSearchTerm,
              payload: searchTerm,
            });
          }}
          handleShowHiddenFilesToggle={() => {
            dispatch({
              type: FileBrowserActionTypes.SetShowHiddenFiles,
              payload: !fileBrowserState.showHiddenFiles,
            });
          }}
          showHiddenFiles={fileBrowserState.showHiddenFiles}
          annotationFiles={annotationFilesQuery.data}
          annotationFile={fileBrowserState.annotationFile}
          annotationDataLoading={annotationDataQuery.isLoading}
          annotationDataError={annotationDataQuery.error ?? undefined}
          onAnnotationFileSelected={(file) => {
            if (!file) {
              dispatch({
                type: FileBrowserActionTypes.ClearAnnotationFile,
                payload: null,
              });
            } else {
              dispatch({
                type: FileBrowserActionTypes.SetAnnotationFile,
                payload: file,
              });
            }
          }}
          annotationViewFilters={fileBrowserState.annotationViewFilters}
          onViewFiltersChanged={(filters) =>
            dispatch({
              type: FileBrowserActionTypes.SetAnnotationViewFilters,
              payload: filters,
            })
          }
          uploadButtonPressed={() => fileInputRef?.current?.click()}
          selectedItems={fileBrowserState.selectedItems}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          marginBottom: theme.spacing(2),
        }}
      >
        <DatasetBreadcrumbs
          datasetId={datasetId}
          datasetPath={datasetPath}
          displayMode={fileBrowserState.displayMode}
          gridItems={fileBrowserState.gridItems}
          handleDisplayToggle={(mode) => {
            if (mode !== null) {
              dispatch({
                type: FileBrowserActionTypes.SetDisplayMode,
                payload: mode,
              });
              setSearchParams({
                display: mode,
              });
            }
          }}
          isUploading={uploadState.isUploading}
          handleGridSliderChange={handleGridSliderChange}
          sliderValueFormat={sliderValueFormat}
        />

        <DragAndDropDisplay
          annotationData={annotationDataQuery.data}
          annotationViewFilters={fileBrowserState.annotationViewFilters}
          currentPage={currentPage}
          datasetId={datasetId}
          displayMode={fileBrowserState.displayMode}
          error={fileBrowserState.error}
          fileInputRef={fileInputRef}
          filePrefix={filePrefix}
          gridItems={fileBrowserState.gridItems}
          handleDeleteRow={(row) => {
            void directoryContentsQuery.refetch();

            dispatch({
              type: FileBrowserActionTypes.DeleteFiles,
              payload: [row],
            });
          }}
          handleRowDoubleClick={handleRowDoubleClick}
          handleSelectRows={(
            newRows: FileSystemNode[],
            isSelected: boolean,
          ) => {
            const selectedItemsMap: Map<string, FileSystemNode> = new Map();

            // Helper function to generate unique keys based on file or directory
            const getKey = (node: FileSystemNode) => {
              if (isFile(node)) {
                return `${node.file.file_id}`;
              } else if (isDirectory(node)) {
                return `${node.directory.relative_path}`;
              }
              return `${node.type}${node.name}`;
            };

            // Add all existing selected items to the map
            fileBrowserState.selectedItems.forEach((item) => {
              selectedItemsMap.set(getKey(item), item);
            });

            if (isSelected) {
              newRows.forEach((row) => selectedItemsMap.set(getKey(row), row));
            } else {
              newRows.forEach((row) => selectedItemsMap.delete(getKey(row)));
            }

            const newItems = [...selectedItemsMap.values()];

            dispatch({
              type: FileBrowserActionTypes.SetSelectedItems,
              payload: newItems,
            });
          }}
          isLoading={directoryContentsQuery.isPending}
          isSearching={Boolean(
            fileBrowserState.searchTerm ||
              fileBrowserState.fileTypes.length > 0,
          )}
          nextToken={nextToken}
          onClearError={() => {
            dispatch({
              type: FileBrowserActionTypes.SetError,
              payload: null,
            });
          }}
          onChangeInput={handleFileUpload}
          onDropComplete={async (error) => {
            if (!error) {
              dispatch({
                type: FileBrowserActionTypes.SetSnackbarState,
                payload: {
                  isOpen: true,
                  message: "Files uploaded successfully",
                },
              });
            }

            await directoryContentsQuery.refetch();
          }}
          onPageChange={(_event, newPage) => {
            if (newPage > fileBrowserState.tableConfig.page) {
              dispatch({
                type: FileBrowserActionTypes.IncrementTablePage,
                payload: { newPage: newPage, nextToken: nextToken },
              });
            } else {
              dispatch({
                type: FileBrowserActionTypes.DecrementTablePage,
                payload: { newPage: newPage },
              });
            }
          }}
          onRowsPerPageChange={(event) => {
            dispatch({
              type: FileBrowserActionTypes.UpdateTableRowsPerPage,
              payload: parseInt(event?.target.value || "", 10) as 10 | 25 | 50,
            });
          }}
          selectedItems={fileBrowserState.selectedItems}
          tableConfig={fileBrowserState.tableConfig}
          uploadActions={uploadActions}
          uploadState={uploadState}
          handleActionConfirmation={handleActionConfirmation}
        />
      </Box>

      <Snackbar
        open={fileBrowserState.snackbarState.isOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={fileBrowserState.snackbarState.message}
        action={
          <RobotoIconButton
            size="small"
            color="inherit"
            eventName={"DatasetExplorerSnackbarClosed"}
            eventProperties={{ datasetId }}
            onClick={handleSnackbarClose}
          >
            <CloseIcon fontSize="small" />
          </RobotoIconButton>
        }
      />

      <AlertDialog
        dialogOpen={fileBrowserState.alert.isOpen}
        handleClose={() => {
          dispatch({
            type: FileBrowserActionTypes.SetAlertState,
            payload: {
              isOpen: false,
              title: "",
              text: "",
              action: async () => {},
            },
          });
        }}
        dialogTitle={fileBrowserState.alert.title}
        dialogText={fileBrowserState.alert.text}
        dialogAction={fileBrowserState.alert.action}
      />
    </>
  );
};
