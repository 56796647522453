import { Box, CircularProgress, TextField, useTheme } from "@mui/material";
import { useState } from "react";
import * as React from "react";

import {
  RobotoButton,
  RobotoTypography,
  RobotoLogo,
  RobotoLink,
} from "@/components";
import { useAuth, useNavigation } from "@/providers";
import { useURLService } from "@/service";

import { AuthCard } from "./AuthCard";
import { AuthCardTitle } from "./AuthCardTitle";
import GoogleOAuthButton from "./GoogleOAuthButton";
import { LegalFooter } from "./LegalFooter";

const buttonWidth = 215;

interface SignInFormProps {
  isVisible: boolean;
  forgotPasswordClicked: () => void;
  emailSignInSuccess: () => void;
  inviteId?: string;
}

export const SignInForm: React.FC<SignInFormProps> = ({
  isVisible,
  forgotPasswordClicked,
  emailSignInSuccess,
  inviteId,
}) => {
  const [showEmailInput, setShowEmailInput] = useState<boolean>(false);

  const theme = useTheme();

  const { generateSignUpURL } = useURLService();

  if (!isVisible) {
    return null;
  }

  return (
    <>
      <RobotoLogo sx={{ maxWidth: "250px" }} />

      <AuthCard>
        <Box
          sx={{ marginTop: theme.spacing(1), marginBottom: theme.spacing(1) }}
        >
          <AuthCardTitle title={"Sign in"} sx={{ fontWeight: 500 }} />
        </Box>
        <GoogleOAuthButton
          signUp={false}
          buttonWidth={buttonWidth}
          marginBottom={theme.spacing(2)}
          marginTop={theme.spacing(2)}
          onClick={() => {
            //clear an error message?
          }}
        />

        {showEmailInput ? (
          <EmailInput signInSuccess={emailSignInSuccess} />
        ) : (
          <RobotoButton
            data-cy={"sign-in-with-email"}
            eventName={"SignInClicked"}
            eventProperties={{ method: "email" }}
            variant="outlined"
            size="large"
            sx={{
              width: buttonWidth,
              marginBottom: theme.spacing(2),
              border: theme.border.thin,
              ":hover": {
                border: theme.border.thin,
                color: theme.palette.text.primary,
              },
              color: theme.palette.text.secondary,
            }}
            onClick={() => setShowEmailInput(true)}
          >
            Sign in with email
          </RobotoButton>
        )}

        <RobotoButton
          sx={{ width: buttonWidth }}
          variant={"text"}
          onClick={forgotPasswordClicked}
          eventName={"ForgotPasswordClicked"}
        >
          Forgot password?
        </RobotoButton>
      </AuthCard>

      <Box>
        <RobotoTypography
          variant={"caption"}
          textAlign={"center"}
          sx={{ marginTop: theme.spacing(2) }}
        >
          Need an account?{" "}
          <RobotoLink data-cy={"sign-up-here"} to={generateSignUpURL(inviteId)}>
            Sign up here
          </RobotoLink>
        </RobotoTypography>
      </Box>

      <LegalFooter />
    </>
  );
};

interface EmailInputProps {
  signInSuccess: () => void;
}

const EmailInput: React.FC<EmailInputProps> = ({ signInSuccess }) => {
  const theme = useTheme();

  const { signIn } = useAuth();

  const [password, setPassword] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [errMsg, setErrMsg] = useState<string>("");

  const { goto } = useNavigation();

  let buttonDisabled = true;

  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  buttonDisabled = !(password.length >= 8 && emailRegex.test(email));

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);

    const err = await signIn(email, password);

    if (err) {
      if (err.toLowerCase().includes("user is not confirmed")) {
        goto.signUp({ interruptedVerificationCode: true });
        return;
      }

      setErrMsg(err);
      setLoading(false);

      return;
    }

    signInSuccess();
  };

  return (
    <Box
      component="form"
      onSubmit={(e) => {
        void onSubmit(e);
      }}
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: buttonWidth,
      }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          marginTop: theme.spacing(1),
        }}
      >
        <TextField
          data-cy={"email-input"}
          label="Email address"
          size="small"
          placeholder="name@domain.com"
          type={"email"}
          sx={{ marginBottom: theme.spacing(2) }}
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
            setErrMsg("");
          }}
          autoFocus
          inputProps={{
            autoCapitalize: "none",
          }}
        />

        <TextField
          data-cy={"password-input"}
          label="Password"
          type={"password"}
          size="small"
          sx={{ marginBottom: theme.spacing(2) }}
          value={password}
          onChange={(e) => {
            setPassword(e.target.value);
            setErrMsg("");
          }}
          inputProps={{
            autoCapitalize: "none",
          }}
        />
      </Box>

      {loading ? (
        <Box
          sx={{
            width: buttonWidth,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: theme.spacing(2),
          }}
        >
          <CircularProgress size={"1.75rem"} />
        </Box>
      ) : (
        <RobotoButton
          data-cy={"login-button"}
          eventName={"LogInClicked"}
          variant={"contained"}
          sx={{ width: buttonWidth, marginBottom: theme.spacing(2) }}
          disabled={buttonDisabled}
          type="submit"
        >
          Log in
        </RobotoButton>
      )}

      {errMsg && (
        <RobotoTypography
          variant={"caption"}
          textAlign={"center"}
          sx={{ color: theme.palette.error.main }}
        >
          {errMsg}
        </RobotoTypography>
      )}
    </Box>
  );
};
