import { Box, useTheme } from "@mui/material";
import * as React from "react";

import { RobotoButton, RobotoLogo, RobotoTypography } from "@/components";
import {
  RobotoRegion,
  humanReadableRobotoRegion,
} from "@/domain/orgs/OrgRecord";

import { AuthCard } from "./AuthCard";
import styles from "./AuthFlowButton.module.css";
import { DataRegionCard } from "./DataRegionCard";
import { LegalFooter } from "./LegalFooter";

interface ConfigureDataRegionFormProps {
  isVisible: boolean;
  regionChosen: (robotoRegion: RobotoRegion) => void;
  goBackPressed: () => void;
}

export const ConfigureDataRegionForm: React.FC<
  ConfigureDataRegionFormProps
> = ({ isVisible, regionChosen, goBackPressed }) => {
  const theme = useTheme();

  const [selectedRegion, setSelectedRegion] = React.useState<RobotoRegion>(
    RobotoRegion.UsWest,
  );

  if (!isVisible) {
    return null;
  }

  return (
    <>
      <RobotoLogo sx={{ maxWidth: "250px" }} />

      <AuthCard>
        <Box>
          <RobotoTypography
            variant="h5"
            textAlign={"center"}
            sx={{ marginBottom: theme.spacing(2) }}
          >
            Data Region
          </RobotoTypography>
          <RobotoTypography variant="body2" textAlign={"center"}>
            Select a region where your data should be stored
          </RobotoTypography>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            mt: theme.spacing(3),
            gap: theme.spacing(2),
            width: "100%",
          }}
        >
          {Object.values(RobotoRegion)
            .sort((a, b) => {
              return -a.localeCompare(b);
            })
            .map((dataRegion) => {
              return (
                <DataRegionCard
                  key={dataRegion}
                  title={humanReadableRobotoRegion(dataRegion)}
                  emoji={dataRegion.startsWith("us") ? "🇺🇸" : "🇪🇺"}
                  selected={selectedRegion === dataRegion}
                  regionSelected={() => {
                    setSelectedRegion(dataRegion);
                  }}
                />
              );
            })}

          <RobotoTypography variant="body2" textAlign={"center"}>
            You can also connect to your own bucket with a premium account.
            Contact us after sign up to learn more.
          </RobotoTypography>
        </Box>
        <Box
          className={styles.authFlowSmallButton}
          sx={{
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            mt: theme.spacing(2),
          }}
        >
          <RobotoButton
            className={styles.authFlowSmallButton}
            data-cy={"continue-button"}
            eventName={"GoToNextStep"}
            eventProperties={{ currentStep: "ConfigureDataRegion" }}
            variant="contained"
            sx={{
              marginBottom: theme.spacing(2),
            }}
            onClick={() => {
              regionChosen(selectedRegion);
            }}
          >
            Continue
          </RobotoButton>

          <RobotoButton
            eventName={"GoToPreviousStep"}
            eventProperties={{ currentStep: "ConfigureDataRegion" }}
            onClick={goBackPressed}
            className={styles.authFlowSmallButton}
          >
            Go back a step
          </RobotoButton>
        </Box>
      </AuthCard>
      <LegalFooter />
    </>
  );
};
